import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Layout from "../components/Layout"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const PageNotFound = () => {
  const data = useStaticQuery(
    graphql`
      query {
        pageNotFound: file(relativePath: { eq: "404-error.png" }) {
          childImageSharp {
            gatsbyImageData(formats: [AUTO, WEBP, AVIF], placeholder: BLURRED)
          }
        }
      }
    `
  )

  const subHeading = "Page not found"
  const mainText =
    "We cant seem to find the page you’re looking for. Is the URL correct?\n" +
    "<br />" +
    "<br />" +
    "Try one of the links below to continue."
  const image = getImage(data.pageNotFound)

  return (
    <Layout
      seo={{ title: "404 error", metaDesc: "Page not found" }}
      location={{ pathname: "page-not-found" }}>
      <section className="bg-pale-grey pt-9">
        <Container>
          <Row className=" d-flex justify-content-sm-center pt-lg-5 ">
            <Col
              xs={12}
              lg={5}
              className="d-flex flex-column justify-content-start justify-content-lg-center align-items-center mb-5 mb-lg-0">
              <GatsbyImage image={image} alt="404" />
            </Col>
            <Col
              xs={12}
              lg={7}
              className="d-flex flex-column justify-content-center align-items-center align-items-xl-start">
              <hgroup>
                <h1>{subHeading}</h1>
              </hgroup>
              <p
                className="pt-4 mb-0 quicksand-500 text-center text-xl-start"
                dangerouslySetInnerHTML={{ __html: mainText }}
              />
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  )
}

export default PageNotFound
